<template>
    <div>
        <div class="position-fixed top-1 d-flex justify-content-center" style="z-index: 11;width:100%">
            <div ref="toast" class="toast align-items-center text-white bg-primary border-0" role="alert" aria-live="assertive" aria-atomic="true">
                <div class="d-flex">
                    <div class="toast-body">
                        <slot></slot>
                    </div>
                    <i class="fas fa-times text-md ms-3 cursor-pointer" data-bs-dismiss="toast" aria-label="Close" v-on:click.stop="$emit('input', false)"></i>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    import { Toast } from 'bootstrap';
    export default {
        props: {
            value: Boolean
        },
        data() {
            return {
                toast: null
            }
        },
        components: {
        },
        methods: {
            showToast: function () {
                this.toast.show();
                setTimeout(() => { this.$emit('input', false) }, 10000);
            },
            hideToast: function () {
                this.toast.hide();
            }
        },
        mounted() {
            this.toast = new Toast(this.$refs.toast)
        },
        watch: {
            value: function (val) {
                if (val) {
                    this.showToast();
                }
                else {
                    this.hideToast();
                }
            }
        }
    }
</script>
