<template>
    <button v-bind="$attrs" 
            type="button" 
            class="btn bg-gradient-primary " 
            v-on:click="$emit('click', $event)"
            v-if="!loading">
        <slot></slot>
    </button>
    <button v-bind="$attrs" v-else class="btn bg-gradient-primary ">
        <span class="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>
    </button>
</template>
<script>
    export default {
        props: {
            loading: {
                type: Boolean,
                default: false
            }
        }
    }
</script>